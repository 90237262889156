import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheaterVideo from "@components/Theater/TheaterVideo"
const wisdomTeethData = require("../_archives/procedures/wisdom-teeth-silverdale.json")

import "../css/pages/w4w-22.scss"

const W4W = () => {
  const image = (name) => `/KTOMS/DEV/${name}.png`
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Wisdom For Wisdom | Kitsap Oral, Maxillofacial & Dental Implant Surgery"
        description="Wisdom For Wisdom provides a student in Silverdale, Poulsbo, or Port Orchard with free wisdom teeth removal. Learn about our Wisdom For Wisdom recipient."
      />
      <div className="w4w-22">
        <div className="w4w-22__hero">
          <img
            src={image("w4w-top-left-corner-image")}
            className="w4w-22__hero-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <img
            src={image("2022-w4w-logo")}
            className="w4w-22__hero-middle"
            alt="wisdom for wisdom logo"
          />
          <img
            src={image("w4w-top-right-corner-image")}
            className="w4w-22__hero-tr w4w-22__shapes-img"
            alt="colorful shapes"
          />
        </div>

        <div className="w4w-22__about">
          <div className="w4w-22__about-content">
            <h1>Making College More Affordable</h1>
            <h2>
              We’re making college more affordable for three local students.{" "}
            </h2>
            <p>
              Our Wisdom For Wisdom program seeks to help students in high
              school and college offset some of the costs of their education by
              providing them with wisdom teeth removal procedures completed at
              one of our offices in Silverdale, Poulsbo, or Port Orchard.
            </p>
            <p>This year our recipients are Gabriel, Grace, and Megan.</p>
            <p>
              Gabriel, 17, from Tacoma, will graduate from Lincoln High School
              before attending Pacific Lutheran University. He will be a first
              generation college student and, even with scholarships, he and his
              family know they will have a difficult time paying for tuition and
              other educational costs.
            </p>
            <p>
              Grace, 19, from Gig Harbor, is a student at The University of
              Alaska Fairbanks. She has aged out of the Washington state dental
              program and now needs to have her wisdom teeth removed as she
              experiences discomfort as they grow in. With her mother already
              working three jobs to help support her and her family, she hoped
              to be a Wisdom For Wisdom recipient so that her family could put
              those funds to her education and other family expenses.
            </p>
            <p>
              Megan, 19, from Poulsbo, will be starting at Florida College in
              Temple Terrace, Florida, this fall to become a nurse. She is one
              of four children and will be paying her own way through college,
              while her parents also provide some support in other ways. Her
              wisdom teeth have just started to grow in and she hopes that by
              having them removed this summer, she will be able to focus on her
              studies.
            </p>
            <p>
              We wish every student the best as they pursue their academic
              endeavors.
            </p>
          </div>
        </div>

        <div className="w4w-22__apply">
          <img
            src={image("w4w-mid-left-image")}
            className="w4w-22__apply-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <div className="w4w-22__apply-content">
            <h2>
              We will share more information about Gabriel, Grace, and Megan’s
              office visits and procedures on{" "}
              <a
                href="https://www.facebook.com/KitsapOMS/"
                title="Visit Us on Facebook"
                target="_blank"
              >
                Facebook
              </a>
              {" and "}
              <a
                href="https://www.instagram.com/kitsapoms/"
                title="Visit Us on Instagram"
                target="_blank"
              >
                Instagram
              </a>
              .
            </h2>
            <p>
              <i>
                Learn more about{" "}
                <a
                  href="https://www.kitsapoms.com/wisdom-teeth-removal-silverdale-wa/"
                  title="Learn more about wisdom teeth"
                >
                  wisdom teeth removal
                </a>
                .
              </i>
            </p>
            <br />
            <br />{" "}
            {/* <p>
              <strong>How to Apply:</strong>
            </p>
            <ul>
              <li>Watch a video about wisdom teeth extraction.</li>
              <li>Take a short quiz.</li>
              <li>Submit your application.</li>
            </ul>
            <h3>
              We wish every student the best as they pursue their academic
              endeavors.{" "}
            </h3>
            <div className="w4w-22__btn-group">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${wisdomTeethData.youtube}`}
                controls={true}
                playing={true}
                onlyButton={true}
                buttonClass="w4w-22__btn"
              />
              <Button
                className="w4w-22__btn"
                buttonText="Take the Quiz"
                href="/w4w-step-1/"
              />
            </div> */}
            <div className="w4w-22__facts">
              <h3>Just the facts&hellip;</h3>
              <ul>
                <li>
                  Wisdom teeth are the only teeth that don’t form before birth.
                </li>
                <li>
                  85% of wisdom teeth will need to be removed at some point.
                </li>
                <li>
                  Wisdom teeth that are impacted (they cannot erupt completely)
                  can cause problems in your mouth and sinus.
                </li>
                <li>Wisdom teeth are also known as third molars.</li>
                <li>
                  Wisdom teeth often appear between the ages of 17 and 25.
                </li>
              </ul>
            </div>
          </div>
          <img
            src={image("w4w-bottom-right-image")}
            className="w4w-22__apply-br w4w-22__shapes-img"
            alt="color shapes"
          />
        </div>
      </div>
    </Layout>
  )
}

export default W4W
